@use '../variables' as *;
@use '../mixins' as *;

.wrapper {
	.projects-page {
		h1 {
			align-self: flex-start;
		}
	}

	footer {
		margin-top: auto;
	}
}

.projects {
	display: grid;
	grid-template-columns: 1fr 1fr;
	width: 100%;
	justify-content: center;
	flex-wrap: wrap;
	gap: 1.5rem;
	margin-bottom: 3rem;
	border-top: 1px solid $lavender;
	padding-top: 1rem;
	@include respond(small) {
		grid-template-columns: 1fr;
	}
}

.category-name {
	text-transform: uppercase;
}
.back {
	color: $off-white;
	text-decoration: none;
	transition: color 300ms;
}

.back:hover {
	color: $light-pink;
}

.cat-list {
	display: flex;
	justify-content: space-between;
	gap: 2rem;
	width: 100%;
	overflow-x: auto;

	span {
		display: flex;
		justify-content: space-between;
		gap: 2rem;
		margin-left: auto;
	}

	a {
		text-decoration: none;
		color: $off-white;
		transition: color 300ms;
		flex: 0 0 auto;
	}

	a:hover {
		color: $light-pink;
	}
}

.cat-list::-webkit-scrollbar {
	height: 15px;
	background-color: transparent;
}

.cat-list::-webkit-scrollbar-thumb {
	background-color: $light-pink;
	border: 15px solid rgba(0, 0, 0, 0);
	background-clip: padding-box;
}

/* Buttons */
.cat-list::-webkit-scrollbar-button:single-button {
	background-color: transparent;

	display: block;
	background-size: 15px;
	background-repeat: no-repeat;
}

.cat-list::-webkit-scrollbar-button:single-button:horizontal:increment {
	height: 15px;
	width: 15px;
	background-position: 0px 0px;
	background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(255, 134, 225)'><polygon points='0,0 0,100 50,50'/></svg>");
}

/* Left */
.cat-list::-webkit-scrollbar-button:single-button:horizontal:decrement {
	height: 15px;
	width: 15px;
	background-position: 0px 0px;
	background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(255, 134, 225)'><polygon points='0,50 50,100 50,0'/></svg>");
}

.cat-list::-webkit-scrollbar-button:single-button:horizontal:decrement:hover {
	background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgba(245, 188, 219, 1)'><polygon points='0,50 50,100 50,0'/></svg>");
}

.cat-list::-webkit-scrollbar-button:single-button:horizontal:decrement:active {
	background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgba(116, 9, 66, 1)'><polygon points='0,50 50,100 50,0'/></svg>");
}

/* Right */
.cat-list::-webkit-scrollbar-button:single-button:horizontal:increment {
	height: 15px;
	width: 15px;
	background-position: 4px 0px;
	background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(255, 134, 225)'><polygon points='0,0 0,100 50,50'/></svg>");
}

.cat-list::-webkit-scrollbar-button:single-button:horizontal:increment:hover {
	background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgba(245, 188, 219, 1)'><polygon points='0,0 0,100 50,50'/></svg>");
}

.cat-list::-webkit-scrollbar-button:single-button:horizontal:increment:active {
	background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgba(116, 9, 66, 1)'><polygon points='0,0 0,100 50,50'/></svg>");
}

.not-found {
	a {
		color: $light-pink;
		transition: color 250ms;

		&:hover {
			color: $off-white;
		}
	}
}
