$font-family: Arial, sans-serif;
$primary: #fff;
$secondary: rgb(255, 134, 225);
$secondary-lt: rgb(255, 134, 225, 0.8);
$secondary-dk: rgba(148, 0, 185, 0.8);

$off-white: rgba(247, 247, 248, 1);
$light: rgba(228, 227, 238, 1);
$light-pink: rgba(245, 188, 219, 1);
$coral: rgba(253, 181, 161, 1);
$lavender: rgba(180, 175, 220, 1);
$dark-rose: rgba(116, 9, 66, 1);
$light-teal: rgba(175, 220, 217, 1);
$medium-blue: rgba(1, 143, 204, 1);
$dark-blue: rgba(36, 61, 135, 1);
$dark-purple: rgba(98, 44, 111, 1);
$dark: rgba(18, 27, 59, 1);
