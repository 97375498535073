@use 'variables' as *;
@use 'mixins' as *;

*,
*::before,
*::after {
	box-sizing: border-box;
}
* {
	margin: 0;
}
html,
body {
	min-height: 100%;
	scroll-behavior: smooth;
}

html {
	overflow-x: hidden;
}

body {
	line-height: 1.5;
	-webkit-font-smoothing: antialiased;
	font-size: 18px;
	font-family: $font-family;
	background-color: $dark;
}

body::after {
	position: absolute;
	width: 0;
	height: 0;
	overflow: hidden;
	z-index: -1;
	content: url('../img/bg06.jpg') url('../img/bg05.jpg') url('../img/bg04.jpg') url('../img/bg03.jpg')
		url('../img/bg02.jpg') url('../img/bg01.jpg');
}

body::-webkit-scrollbar-track {
	box-shadow: inset 0 0 10px rgba(0, 0, 0, 0);
	-webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0);
	background-color: $dark;
}

body::-webkit-scrollbar {
	width: 10px;
	background-color: $dark;
}

body::-webkit-scrollbar-thumb {
	background-color: $light-pink;
}

#bg {
	position: fixed;
	z-index: -1;
	width: 100vw;
	height: 100vh;
	transition: background-image 3000ms linear;
	background-size: cover;
	background-position: center;
	background-color: #000;
	background-image: url('../img/bg01.jpg');
}

#overlay {
	position: fixed;
	z-index: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.4);
}

#root {
	position: absolute;
	width: 100%;
	height: 100%;
}

.preload {
	-moz-animation: none !important;
	-webkit-animation: none !important;
	-ms-animation: none !important;
	animation: none !important;
	-moz-transition: none !important;
	-webkit-transition: none !important;
	-ms-transition: none !important;
	transition: none !important;
}

.wrapper {
	display: flex;
	flex-direction: column;
	width: 100vw;
	height: 100%;
	@include respond(small) {
		padding: 8vw;
		gap: 3rem;

		header {
			h1 {
				margin: 0 0 3rem;
			}
		}
	}
	padding: 2rem 3rem;
	@include respond(x-large) {
		padding: 2rem 10rem;
	}
	color: $primary;

	.hero {
		margin-top: auto;
		display: flex;
		flex: 1;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		gap: 1.5rem;

		h1 {
			font-size: clamp(2.5rem, 5vw, 5rem);
		}

		p {
			font-size: 1.25rem;
		}

		.btn {
			border-radius: 0.5rem;
			padding: 1rem 2rem;
			text-decoration: none;
			background-color: $secondary-lt;
			color: $primary;
			font-family: $font-family;
			margin: 1rem 0;
			@include transition();
			box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.7);
		}

		.btn:hover {
			background-color: $secondary-dk;
		}

		.hero-buttons {
			display: flex;
			flex-direction: column;
			gap: 1rem;
			align-items: flex-start;
		}

		nav {
			display: flex;
			align-items: flex-start;

			gap: 3rem;
			svg {
				width: 3rem;
				height: 3rem;
				font-size: 3rem;
				path {
					fill: $primary;
					@include transition();
				}
			}

			svg:hover {
				cursor: pointer;
			}

			svg:hover path {
				fill: $secondary;
			}
			@include respond(small) {
				gap: 8vw;

				svg {
					width: 6vw;
					height: 6vw;
					font-size: 6vw;
				}
			}
		}
	}

	.header-nav {
		ul {
			list-style-type: none;
			display: flex;
			gap: 2rem;
			padding: 0;

			a {
				color: $primary;
				text-decoration: none;
				@include transition();
			}

			a:hover {
				color: $secondary;
			}
		}
	}

	footer {
		font-size: 1rem;
		margin-top: auto;
		padding-bottom: 2rem;
		color: $primary;
		a {
			color: $primary;
			@include transition();
		}

		a:hover {
			color: $secondary;
		}
	}
}

.loading-div {
	display: flex;
	width: 100%;
	height: 400px;
	justify-content: center;
	align-items: center;
}
