@use '../variables' as *;
@use '../mixins' as *;

.project {
	width: 100%;
	margin: 1rem 0;
	padding: 0.5rem 2rem 2rem;
	background-color: $light;
	color: $dark;
	border-radius: 0.5rem;

	@include respond(small) {
		font-size: 16px;
		padding: 0.5rem 1rem 1rem;
	}

	#contents {
		padding: 0.5rem 1rem;

		ul {
			list-style-type: none;
			padding: 0;
			width: 100%;
		}

		a {
			text-decoration: none;
			padding: 0.25rem;
			display: block;
			&:hover {
				background-color: $dark-purple;
			}
		}
	}

	ul {
		background-color: $off-white;
		width: 80%;
		margin: 1.5rem auto;
		padding: 1rem 1rem 1rem 2.5rem;
		border-radius: 0.5rem;

		@include respond(small) {
			margin: 1.5rem 0;
			width: 100%;
			max-width: 100%;
		}

		ul {
			margin: 0;
		}
		li {
			color: $dark;
		}
	}
}

hr {
	background-color: transparent;
	border-top: 1px solid $lavender;
	border-bottom: none;
	margin-top: 0.5rem;
}

.project img {
	max-width: 80%;
	object-fit: contain;
	display: block;
	margin: 1rem auto;
	border-radius: 0.5rem;
}

.project-image {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	padding: 1rem;
	margin: 1rem 0;
	border-radius: 0.5rem;
	width: 100%;
	height: 300px;
	background-size: cover;
}

h2 {
	margin: 1.5rem 0;
	width: 100%;
	display: flex;
	align-items: baseline;
	gap: 0.25rem;
	border-bottom: 1px solid $lavender;
	a {
		text-decoration: none;
		font-weight: 200;
		opacity: 0.5;
		transition: opacity 250ms;
		margin-left: auto;

		&:hover {
			opacity: 1;
		}
	}
}

#project-title {
	justify-content: space-between;

	.title-category {
		font-size: 1rem;
		font-weight: 600;
		text-transform: uppercase;
		text-decoration: none;

		a {
			margin: 0 0.5rem;
		}
	}
}

a {
	color: $dark-blue;
	transition: color 250ms;
	&:hover {
		color: $secondary;
	}
}

p {
	margin: 1rem 0;
}

pre {
	margin: 1.5rem auto;
	padding: 0;
	width: 80%;
	border-radius: 0.5rem;
	overflow: auto;

	div {
		margin: 0 !important;

		&::-webkit-scrollbar-track {
			background-color: $dark;
		}

		&::-webkit-scrollbar {
			height: 5px;
			background-color: $dark;
		}

		&::-webkit-scrollbar-thumb {
			background-color: $light-pink;
		}
	}

	@include respond(small) {
		font-size: 0.75rem;
		width: 100%;
	}

	code {
		.linenumber:last-child {
			display: none !important;
		}
	}
}

table {
	width: fit-content;
	max-width: 80%;
	margin: 2rem auto;
	background-color: $off-white;
	border-spacing: 0;
	border-radius: 0.5rem;
	border: 2px solid $lavender;
	box-shadow: 0.25rem 0.25rem 0.5rem rgba(0, 0, 0, 0.1);

	@include respond(small) {
		max-width: 100%;
	}

	thead {
		display: none;
	}

	tbody {
		tr:nth-of-type(odd) {
			background-color: $lavender;

			td {
				padding: 0.5rem 1rem;
			}
		}

		.table-img {
			max-width: 100%;
			max-height: 100%;
			margin: 0 auto;
			border-radius: 0;
		}

		tr:last-child {
			td:first-child {
				.table-img {
					border-bottom-left-radius: 0.5rem;
				}
			}
			td:last-child {
				.table-img {
					border-bottom-right-radius: 0.5rem;
				}
			}
		}
	}
}

.table-with-text {
	table {
		tbody {
			tr:nth-of-type(even) {
				td {
					padding: 0.5rem 1rem;
				}
			}
		}
	}
}

.bottom-nav {
	display: grid;
	width: calc(100% - 1rem);
	grid-template-columns: 1fr 1fr 1fr;
	margin: 0 0.5rem 2rem;
	text-align: center;
	a {
		display: flex;
		flex-direction: column;
		gap: 0.5rem;
		color: $light;
		transition: color 250ms;
		text-decoration: none;
		&:hover {
			color: $secondary;
		}

		&:first-child {
			align-items: flex-start;
		}

		&:last-child {
			align-items: flex-end;
		}
	}

	.bottom-link {
		@include respond(small) {
			display: none;
		}
	}
}
