@mixin transition ($property: all, $duration: 500ms) {
	transition-duration: $duration;
	transition-property: $property;
}

@mixin respond ($breakpoint) {
	@if $breakpoint == small {
		@media only screen and (max-width: 600px) {
			@content;
		}
	}

	@if $breakpoint == medium {
		@media only screen and (max-width: 700px) {
			@content;
		}
	}

	@if $breakpoint == x-large {
		@media only screen and (min-width: 1200px) {
			@content;
		}
	}
}
